import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./MyComponents/login";

import Logout from "./MyComponents/logout";
import MasterLogin from "./master/login_master";
import Company from "./master/company";
import Newcompany from "./master/newcompany";
import MasterNavbar from "./master/NavbarMaster";
import MasterReports from "./master/reports";
import MasterHome from "./master/home";

import Nav from "./claim/Navbar";
import Claim from "./claim/claim";
import ClaimUpadte from "./claim/update";
import ClaimReview from "./claim/review";
import ImageView from "./MyComponents/Imageview";
import ImageUpdateUpload from "./claim/update_image";
import Payment_Claim from "./claim/payment";
import Allclaimbycompany from "./claim/allclaimbycompany";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Nav />
            <Allclaimbycompany />
            
          </Route>
          <Route  path="/home">
          <Nav />
            <Allclaimbycompany />
            
          </Route>

  
          <Route path="/login">
            <Login />
          </Route>
        

          <Route path="/masterlogin">
            <MasterLogin />
          </Route>
          <Route path="/company">
            <MasterNavbar />
            <Company />
          </Route>
          <Route path="/newcompany">
            <MasterNavbar />
            <Newcompany />
          </Route>
          <Route path="/selectcompany">
            <MasterNavbar />
            <MasterReports />
          </Route>
          <Route path="/masterhome">
            <MasterNavbar />
            <MasterHome />
          </Route>
       
          <Route path="/newclaim">
        
            <Nav />
            <Claim />
          </Route>
          <Route path="/paymentclaim">
          <Nav />
            <Payment_Claim />
          </Route>
          <Route path="/claimupdate">
          <Nav />
            <ClaimUpadte />
          </Route>
          <Route path="/review">
            <ClaimReview />
          </Route>
          <Route path="/imageview">
          <Nav />
            <ImageView />
          </Route>
          <Route path="/imageUpdateUpload">
            <ImageUpdateUpload />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
