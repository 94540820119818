import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Display, CircleFill } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import "../MyComponents/index.css";

function NewTruck() {
  const history = useHistory();

  const [name, setName] = useState("");
  const [uname, setUName] = useState("");

  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [pass, setPassword] = useState();
  const [add, setAdd] = useState();
  const [ca, setCa] = useState();
  const [usdot, setUsdot] = useState();
  const [city, setCity] = useState();
  const [state, setstate] = useState();
  const [zip, setZip] = useState();
  const [fax, setFax] = useState();
  const [country, setCountry] = useState();
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const newTruck = async (e) => {
    e.preventDefault();

    if (name === "" || email === "" || pass === "") {
      alert("Please Enter Ell Fields !!");
    } else {
      await fetch(
        `/apis/hazmat/company.php?country=${country}&fax=${fax}&zip=${zip}&state=${state}&city=${city}&name=${name}&phone=${phone}&email=${email}&pass=${pass}&add=${add}&ca=${ca}&usdot=${usdot}`
      )
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          json[0].msg === "pass"
            ? history.push("/company")
            : alert("Sorry! try again");


            
        })
        .catch((err) => {
          alert(err);
          alert("Sorry! try again");
        });
    }
  };
  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY_MASTER"));
    if (v == null) {
    } else if (v[0].msg === "pass") {
      setUName(v[0].name);
    } else {
      history.push("/login");
    }
  }, []);
  return (
    <div
      className="container"
      style={{ marginTop: "70px", marginBottom: "70px" }}
    >
      <form className="needs-validation">
        <strong className="d-inline-block mb-2 text-success">
          <h3>New Company</h3>
        </strong>
        <div className="row">
          <div className="col-sm-6 col-12">
            <p>
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Company Name </label>
            <input
              type="text"
              value={name}
              onChange={(t) => {
                setName(t.target.value);
              }}
              name="name"
              placeholder="Company Name"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Company Phone#</label>
            <input
              type="text"
              value={phone}
              onChange={(t) => {
                setPhone(t.target.value);
              }}
              name="phone"
              placeholder="Company Phone#"
              className="form-control form-control-sm"
            />
          </div>
        </div>

        <div className="row"></div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Address </label>
            <input
              type="text"
              value={add}
              onChange={(t) => {
                setAdd(t.target.value);
              }}
              placeholder="Address"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Fax </label>
            <input
              type="text"
              value={fax}
              onChange={(t) => {
                setFax(t.target.value);
              }}
              placeholder="Fax"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Ca </label>
            <input
              type="text"
              value={ca}
              onChange={(t) => {
                setCa(t.target.value);
              }}
              placeholder="Ca"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Usdot </label>
            <input
              type="text"
              value={usdot}
              onChange={(t) => {
                setUsdot(t.target.value);
              }}
              placeholder="Usdot"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">City </label>
            <input
              type="text"
              value={city}
              onChange={(t) => {
                setCity(t.target.value);
              }}
              placeholder="City"
              className="form-control form-control-sm"
            />
          </div>

          <div className="col-sm-4 col-4">
            <label for="validationCustom01">State </label>

            <Form.Select
              value={state}
              onChange={(t) => {
                setstate(t.target.value);
              }}
              size="sm"
            >
              <option>Unspecified</option>
              <option>CA</option>
              <option>AL</option>
              <option>AK</option>
              <option>AZ</option>
              <option>AR</option>
              <option>CA</option>
              <option>CO</option>
              <option>CT</option>
              <option>DE</option>
              <option>DC</option>
              <option>FL</option>
              <option>GA</option>
              <option>HI</option>
              <option>ID</option>
              <option>IL</option>
              <option>IN</option>
              <option>IA</option>
              <option>KS</option>
              <option>KY</option>
              <option>LA</option>
              <option>ME</option>
              <option>MT</option>
              <option>NE</option>
              <option>NV</option>
              <option>NH</option>
              <option>NJ</option>
              <option>NM</option>
              <option>NY</option>
              <option>NC</option>
              <option>ND</option>
              <option>OH</option>
              <option>OK</option>
              <option>OR</option>
              <option>MD</option>
              <option>MA</option>
              <option>MI</option>
              <option>MN</option>
              <option>MS</option>
              <option>MO</option>
              <option>PA</option>
              <option>RI</option>
              <option>SC</option>
              <option>SD</option>
              <option>TN</option>
              <option>TX</option>
              <option>UT</option>
              <option>VT</option>
              <option>VA</option>
              <option>WA</option>
              <option>WV</option>
              <option>WI</option>
              <option>WY</option>
              <option>MEX</option>
              <option>AB</option>
              <option>BC</option>
              <option>MB</option>
              <option>NB</option>
              <option>NL</option>
              <option>NS</option>
              <option>NT</option>
              <option>NU</option>
              <option>ON</option>
              <option>PE</option>
              <option>QC</option>
              <option>SK</option>
              <option>YT</option>
            </Form.Select>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Zip </label>
            <input
              type="text"
              value={zip}
              onChange={(t) => {
                setZip(t.target.value);
              }}
              placeholder="Zip"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Country </label>
            <Form.Select
              value={country}
              onChange={(t) => {
                setCountry(t.target.value);
              }}
              size="sm"
            >
              <option>Unspecified</option>
              <option>Canada</option>
              <option>Australia</option>
              <option>UK</option>
              <option>NewZealand</option>
              <option>SouthAfrica</option>
              <option>UnitedArabEmirates</option>
              <option>TrinidadAndTobago</option>
            </Form.Select>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Email </label>
            <input
              
              value={email}
              onChange={(t) => {
                setEmail(t.target.value);
              }}
              name="name"
              placeholder="Company Email"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">Password </label>
            <input
              
              value={pass}
              onChange={(t) => {
                setPassword(t.target.value);
              }}
              name="password"
              type={isRevealPwd ? "text" : "password"}
              placeholder="Company Password"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div style={{ marginTop: "5px" }} className="row">
          <div className="col-sm-4 col-4"></div>
          <div className="col-sm-4 col-4">
            <div className="form-check">
              <input
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                className="form-check-input"
                type="checkbox"
                id="havekey"
              />
              <label
                className="form-check-label"
                style={{ fontSize: "12px" }}
                htmlFor="havekey"
              >
                {isRevealPwd === true ? "Hide Password" : "Show Password"}
              </label>
            </div>
          </div>
        </div>

        <button
          onClick={newTruck}
          style={{ backgroundColor: "#48b4e0", marginTop: "20px" }}
          type="button"
          className="btn btn-secondary"
        >
          Submit
        </button>
      </form>
    </div>
  );
}
export default NewTruck;
