import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Trash ,ArrowRight} from "react-bootstrap-icons";
import axios from "axios";
import { BrowserRouter as Router, Link,useHistory } from "react-router-dom";

function ViewTruck() {

  const history=useHistory()
  const [all, setAll] = useState([]);


  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    if (v === null) {
       history.push("/login")
       console.log("loginPlease")    
    } else {
      console.log(v)
      getAllclaims(v[0].cid);
    }
  }, []);

//upload start
const getAllclaims = async (cid) => {
    const data = new FormData();
   
    data.append("cid",cid);

       axios({
        method: "post",
        url: "/apis/hazmat/view_hazmatclaim.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((s)=>{
    
        if (s.data.length ===0) {

          
        }else{
            if(s.data[0].msg=="pass")
            {
                setAll(s.data)
                console.log(s.data)
            }
        }

      }
        
      
      ).catch((s)=>(
        console.log("Login failed")
      ));
};



  return (
    <div className="container-sm">
    


 

      <table style={{ marginTop: "30px" }} className="table table-striped">
        <thead>
          <tr>
            <th>Driver Name</th>
            <th>Driver Phone</th>
            <th>Plate State</th>
            <th>Year</th>
            <th>Make</th>
            <th>Model</th>
            <th>Vin</th>
             <th>Action</th>
          </tr>
     
        </thead>
        <tbody>
          {all.map((v, i) => (
            <tr key={i} onClick={()=>{
              localStorage.setItem("claimID",v.cid)
              console.log(v.cid)
              history.push("/claimupdate")
              }}>
              <td>{JSON.parse(v.driver)[0].name} </td>
              <td>{JSON.parse(v.driver)[0].phone} </td>
              <td>{JSON.parse(v.driver)[0].platcar} </td>
              <td>{JSON.parse(v.driver)[0].yearcar} </td>
              <td>{JSON.parse(v.driver)[0].makecar} </td>
              <td>{JSON.parse(v.driver)[0].modelcar} </td>
              <td>{JSON.parse(v.driver)[0].vincar} </td>
             
            
              <td>
                {" "}
                <ArrowRight color="blue" size={20}  />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default ViewTruck;
