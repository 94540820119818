import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Display, CircleFill,Trash } from "react-bootstrap-icons";

function Record() {
  const [row, setRow] = useState();
  const [uname, setUname] = useState("");

  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [ref, setRef] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [all, setAll] = useState([]);

  const [due, setDue] = useState("");
  const [paid, setpaid] = useState("");
  const [total, settotal] = useState("");

  const history = useHistory();

  const setRecord = async () => {

    await fetch(
      `/apis/hazmat/payment_add_claim.php?mid=${row}&ref=${ref}&type=${type}&amount=${amount}&date=${date}&time=${time}&status=add`
    )
      .then((response) => response.json())
      .then((json) => {

        setType("")
        setAmount("")
        setRef("")
        setDate("")
        setTime("")
        as()
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getServerReview = async (id) => {
    await fetch(`/apis/hazmat/viewrecord.php?id=${id}&status=all`)
      .then((response) => response.json())
      .then((json) => {
        if(json[0].msg=="fail")
        {
          getServerClaim(id,0)
          setAll([])
        }else{
          const ass= json.reduce((n, { amount }) => n + Number(amount), 0);
          setAll(json)
          getServerClaim(id,ass)
        }
        
       
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const deleteo = async (id) => {
    await fetch(`/apis/hazmat/payment_add_claim.php?id=${id}&status=delete`)
      .then((response) => response.json())
      .then((json) => {

       
  as()
      
       
      })
      .catch((err) => {
        //console.log(err);
   as()
      });
  };

const as=()=>{
  const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
  let a = localStorage.getItem("claimID");
  

  if (v === null) {
    console.log("V is null");
  } else if (a === null) {
    console.log("a is null");
  } else {
    getServerReview(a)
    
    setRow(a);
    setUname(v[0].name);
  }
}

  useEffect(() => {
    as()
  }, []);
  const getServerClaim = async (id,payed) => {
    

    await fetch(`/apis/hazmat/view_claim.php?id=${id}`)
      .then((response) => response.json())
      .then((json) => {

        
        const as=JSON.parse(json[0].invoice);
        const bill=as[0].total;
        settotal(bill)

       setDue(bill-payed)


         setpaid(payed)
      
        console.log("invoice_total",json)
       
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return (
    <div className="container-sm">
      <div style={{ marginTop: "30px" }}>
        <strong className="d-inline-block mb-2" style={{color:"#3d85c6"}}>
          {" "}
          <h4> Hazmat: Record Payment </h4>
        </strong>
      </div>

      
        <div className="row">
          <div className="col-sm-6 col-12">
            <p>
              <CircleFill
                color="#1ceb15"
                size={12}
                style={{ marginRight: "3px" }}
              />{" "}
              Active |{" "}
              <Display
                size={20}
                style={{ marginRight: "3px", marginLeft: "3px" }}
              />
              <> {uname}</>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">
              {" "}
              Total Amount:
              <span
                style={{ color: "red", fontSize: "15px", fontWeight: "bold" }}
              >
                {"  $"}
                {total}
              </span>{" "}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">
              {" "}
              Total Paid:
              <span
                style={{ color: "red", fontSize: "15px", fontWeight: "bold" }}
              >
                {"  $"}
                {paid}
              </span>{" "}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">
              {" "}
              Due Amount:
              <span
                style={{ color: "red", fontSize: "20px", fontWeight: "bold" }}
              >
                {"  $"}
                {due}
              </span>{" "}
            </label>
          </div>
        </div>
        <input
          type="radio"
          id="cash"
          style={{ marginLeft: "5px", marginTop: "20px" }}
          name="fav_language"
          value="cash"
          checked
          onChange={() => {
            setType("Cash");
          }}
        />
          <label for="cash">Cash</label>
        <input
          type="radio"
          id="Check"
          name="fav_language"
          onChange={() => {
            setType("Check");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Check">Check</label>
        <input
          type="radio"
          id="visa"
          name="fav_language"
          onChange={() => {
            setType("Visa");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="visa">Visa</label> 
        <input
          type="radio"
          id="mc"
          onChange={() => {
            setType("MasterCard");
          }}
          name="fav_language"
          value="MasterCard"
          style={{ marginLeft: "10px" }}
        />
          <label for="mc">MasterCard</label> 
        <input
          type="radio"
          id="d"
          name="fav_language"
          value="Discover"
          onChange={() => {
            setType("Discover");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="d">Discover </label> 
        <input
          type="radio"
          id="ae"
          name="fav_language"
          onChange={() => {
            setType("American Express");
          }}
          value="American Express"
          style={{ marginLeft: "10px" }}
        />
          <label for="ae">American Express</label> 
        <br /> 
        <input
          type="radio"
          id="oth"
          name="fav_language"
          value="Other"
          onChange={() => {
            setType("Other");
          }}
          style={{ marginRight: "5px" }}
        />
        <label for="oth">Other</label> 
        <input
          type="radio"
          id="Debit"
          name="fav_language"
          onChange={() => {
            setType("Debit");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Debit">Debit</label> 
        <input
          type="radio"
          id="Account"
          name="fav_language"
          onChange={() => {
            setType("Account");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Account">Account</label> 
        <input
          type="radio"
          id="EFT"
          name="fav_language"
          onChange={() => {
            setType("EFT");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="EFT">EFT</label> 
        <input
          type="radio"
          id="un"
          name="fav_language"
          onChange={() => {
            setType("Uncollectable Bad Debt");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="un">Uncollectable Bad Debt</label> 
        <input
          type="radio"
          id="cc"
          name="fav_language"
          onChange={() => {
            setType("Credit Card");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="cc">Credit Card</label> 
        <input
          type="radio"
          id="Square"
          name="fav_language"
          onChange={() => {
            setType("Square");
          }}
          style={{ marginLeft: "10px" }}
        />
          <label for="Square">Square</label> 
        <div style={{ marginTop: "20px" }} className="row">
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Pay Amount </label>

            <input
              type="number"
              value={amount}
              onChange={(t) => {
                setAmount(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Payment Amount"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-4 col-4">
            <label for="validationCustom01">
              Payment Reference Number (optional){" "}
            </label>

            <input
              type="number"
              value={ref}
              onChange={(t) => {
                setRef(t.target.value);
              }}
              id="fname"
              name="name"
              placeholder="Payment Reference Number (optional)"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Date </label>

            <input
              type="date"
              value={date}
              onChange={(t) => {
                setDate(t.target.value);
              }}
              id="fname"
              name="name"
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-2 col-4">
            <label for="validationCustom01">Time</label>

            <input
              type="time"
              value={time}
              onChange={(t) => {
                setTime(t.target.value);
              }}
              id="fname"
              name="name"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <button
          style={{
            backgroundColor: "#3d85c6",
            marginTop: "20px",
            width: "80px",
            color:"white"
          }}
          type="button"
          className="btn "
          onClick={setRecord}
        >
          Pay
        </button>
        <button
          style={{
            color:"white",
            backgroundColor: "#ffa500",
            marginTop: "20px",
            width: "80px",
            marginLeft: "20px",
          }}
          type="button"
          className="btn btn-"
          onClick={() => {
            history.push("/claimupdate");
          }}
        >
          Back
        </button>
        <table  style={{marginTop:"40px"}} class="table table-sm">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Type</th>
      <th scope="col">Reference</th>
      <th scope="col">Amount</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
  {
          all.map((v,i)=>(
            <>
                  <tr>
      <th scope="row">{i+1}</th>
      <td>{v.type}</td>
      <td>{v.ref}</td>
      <td>${v.amount}</td>
      <td> <Trash color="#144a73" onClick={() => deleteo(v.id)} size={25} /> </td>
    </tr>   
            </>
          ))
        }

  </tbody>
</table>
{/* <table>

<tr>
     <th style={{textAlign:"right"}} colSpan={3}><b>Total: </b></th>
     <th colSpan={2}> {invoice}</th>
   </tr>
</table> */}

{/* <div style={{marginTop:"20px"}} className="row">
  <div className="col-7">

  </div>
  <div className="col-3">
  <p style={{textAlign:"right",paddingRight:"40%",fontSize:"22px"}}>Received Amount:</p>
  </div>
  <div className="col-2">
  <p style={{textAlign:"right",paddingRight:"40%",fontSize:"26px"}}> {paid}</p>
  </div>
 
</div> */}


    



   
    </div>
  );
}
export default Record;
