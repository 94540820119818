import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import axios from "axios";



import "../MyComponents/index.css";
import hide from "../image/hidden.png";
import show from "../image/show.png";
import "./index.css";

function Login() {
  const [email, setEmail] = useState("");
  const [pass, setPassword] = useState("");


  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const history = useHistory();




//upload start
const loginServer = async () => {
    const data = new FormData();
    data.append("email", email);
    data.append("pass", pass);

       axios({
        method: "post",
        url: "/apis/hazmat/c_login.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((s)=>{
    
        console.log(s.data)
        if (s.data.length===0) {

          console.log("Login array null")
        } else if (s.data[0].msg === "pass") {
          localStorage.setItem("LOCAL_KEY", JSON.stringify(s.data));
          history.push("/home");
        }else {
          alert("Invalid Email or Password! Please try again.");
        }

      }
        
      
      ).catch((s)=>(
        console.log("fail")
      ));
};
 


  return (
    <center>
      <div className="col-md-4" style={{ marginTop: "100px" }}>
        <div className="card-body">
          <center>
            
              <div className="pwd-container">
                <div className="card-header">
                  <h4>Hazmat Login</h4>
                </div>
              </div>
          
              <div className="pwd-container">
                <input
                  required
                  type="email"
                  style={{ marginTop: "20px" }}
                  value={email}
                  onChange={(t) => {
                    setEmail(t.target.value);
                  }}
                  className="form-control"
                 
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                />
              </div>
       

              <div className="pwd-container">
                <input
                  className="form-control"
                
                  placeholder="Enter Password"
                  autoComplete="off"
                  style={{ marginTop: "20px" }}
                  value={pass}
                 
                  
                  type={isRevealPwd ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  style={{ height: "20px", width: "20px" }}
                  title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? hide : show}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
   
              </div>
               
            
              <br />
              <button
                
                style={{ marginTop: "20px" }}
                onClick={loginServer}
                className="btn btn-primary"
              >
                Login Now
              </button>
         
          </center>
        </div>
      </div>
    </center>
  );
}

export default Login;
