import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Trash } from "react-bootstrap-icons";
import { BrowserRouter as Router, Link } from "react-router-dom";

function ViewTruck() {
  const [allCom, setAllCom] = useState([]);

  const getAllCompanyServer = async () => {
    await fetch(`/apis/hazmat/companylist.php`)
      .then((response) => response.json())
      .then((json) => {
        setAllCom(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY_MASTER"));

    if (v === null) {
    } else {
      getAllCompanyServer();
    }
  }, []);

  const del = async (id) => {
    await fetch(`/apis/hazmat/del.php?id=${id}&t=company`)
      .then((response) => response.json())
      .then((json) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Link
        to="/newcompany"
        style={{
          backgroundColor: "#48b4e0",
          fontSize: "18px",

          marginTop: "20px",
        }}
        className="btn btn-secondary"
      >
        Add New Company
      </Link>
      <table style={{ marginTop: "30px" }} className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Password</th>
            <th>Address</th>
            <th>Ca</th>

            <th>Usdot</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>Fax</th>
            <th>Country</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {allCom.map((i, index) => (
            <tr key={index}>
              <td>{i.cname} </td>
              <td>{i.cphone} </td>
              <td>{i.cemail} </td>
              <td>{i.cpass} </td>
              <td>{i.cadd} </td>
              <td>{i.cca} </td>
              <td>{i.cusdot} </td>
              <td>{i.ccity} </td>
              <td>{i.cstate} </td>
              <td>{i.czip} </td>
              <td>{i.cfax} </td>
              <td>{i.ccountry} </td>
              <td>
                <Trash color="gray" size={20} onClick={() => del(i.cid)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default ViewTruck;
