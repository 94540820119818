import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Form,
  FormControl,
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import logo from "../image/logo.png";
import { BrowserRouter as Router, useHistory, Link } from "react-router-dom";

function Newimpound() {
  const [log, setLog] = useState([]);


  const history = useHistory();

  const logout = () => {
    localStorage.removeItem("LOCAL_KEY");
    history.push("/login");
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY"));
    if (v == null) {
      history.push("/login");
    } else {
      setLog(v);
     
    }
  }, []);

  return (
    <div>
      <Navbar style={{ background: "#108dfa", color: "#ffffff" }} expand="lg">
        <Container fluid>
          <Navbar.Brand href="home">
            {" "}
            <img
              src={logo}
              alt="LOGO"
              style={{
                height: "40px",
               
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link
                style={{
                  color: "#ffffff",

                  fontSize: "18px",
                }}
                className="nav-link"
                to="/home"
              >
                Home
              </Link>

              {log === null ? (
                history.push("/login")
              ) : (
                <Link
                  style={{
                    color: "#ffffff",

                    fontSize: "18px",
                  }}
                  to="/newclaim"
                  className="nav-link"
                 
                >
                  New Claim
                </Link>
              )}
              {log === null ? (
                history.push("/login")
              ) : (
                <p
                
                onClick={()=>{

                  logout()
                }}
                  style={{
                    color: "#ffffff",

                    fontSize: "18px",
                  }}
                  className="nav-link"
                  
                >
                  Logout
                </p>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
export default Newimpound;
