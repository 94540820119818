import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

import { Form } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";

function ViewTruck() {
  const [allCom, setAllCom] = useState([]);
  const [select, setSelect] = useState("");
  const [show, setShow] = useState(false);

  const getAllCompanyServer = async () => {
    await fetch(`/apis/hazmat/companylist.php`)
      .then((response) => response.json())
      .then((json) => {
        setAllCom(json);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem("LOCAL_KEY_MASTER"));

    if (v === null) {
    } else {
      getAllCompanyServer();
    }
  }, []);
//upload start
const loginServer = async (e,p) => {
  const data = new FormData();
  data.append("email", e);
  data.append("pass", p);

     axios({
      method: "post",
      url: "/apis/hazmat/c_login.php",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((s)=>{
  
      console.log(s.data)
      if (s.data.length===0) {

        console.log("Login array null")
      } else if (s.data[0].msg === "pass") {

        setShow(true)
        localStorage.setItem("LOCAL_KEY", JSON.stringify(s.data));
        // history.push("/home");
      }else {
        alert("Invalid Email or Password! Please try again.");
      }

    }
      
    
    ).catch((s)=>(
      console.log("fail")
    ));
};
  return (
    <div className="container-sm">
      <center>
        <div style={{ marginTop: "40px" }} className="col-sm-8  col-4">
          <label htmlFor="validationCustom01">
            Please, Select any Company for more details 
          </label>
        </div>
        <div style={{ marginTop: "10px" }} className="col-sm-4  col-4">
          <Form.Select
            size="sm"
            value={select}
            onChange={(t) => {
              if (t.target.value === "a") {
              } else {
                setSelect(t.target.value);
                const a = t.target.value;

                loginServer(allCom[a].cemail,allCom[a].cpass)
               
              }
            }}
          >
            <option value="a">Select Company</option>
            {allCom.map((i, index) => (
              <option value={index}>{i.cname}</option>
            ))}
          </Form.Select>
        </div>

        { show?<>   <Link
          style={{ marginTop: "20px", backgroundColor: "#48b4e0" }}
          className="btn btn-secondary"
          to="home"
          target="_blank"
        >
          Get Started
        </Link></>:<></>

        }
     
      </center>
    </div>
  );
}
export default ViewTruck;
